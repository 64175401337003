import { postData, querySearch } from '@utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/roles')
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },

    fetchPricelist() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getPriceLists',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchCustomerGroup() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getMemberGroups',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchRole() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getProfileList',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAdmins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        postData(querySearch(queryParams, 'getAdminUsers'))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchAdmin(ctx, adminData) {
      return new Promise((resolve, reject) => {
        postData(adminData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addAdmin(ctx, adminData) {
      return new Promise((resolve, reject) => {
        postData(adminData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteAdmin(ctx, adminData) {
      return new Promise((resolve, reject) => {
        postData(adminData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
