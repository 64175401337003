<template>
  <v-navigation-drawer
    :value="isFilterUserSidebarActive"
    temporary
    touchless
    floating
    stateless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    :class="$vuetify.breakpoint.smAndUp ? 'rounded-tl-xl rounded-bl-xl' : null"
    @input="(val) => $emit('update:is-user-filter-sidebar-active', val)"
  >
    <v-sheet
      :color="isDark ? '#363351' : '#fafafa'"
      class="pa-3 d-flex align-center justify-space-between"
    >
      <div class="d-flex align-center">
        <v-avatar
          size="48"

          color="secondary"
        >
          <v-icon
            size="24"
            class="white--text"
          >
            {{ icons.mdiFilter }}
          </v-icon>
        </v-avatar>
        <div class="text-h6 font-weight-medium ml-3">
          Filtreleme
        </div>
      </div>
      <v-btn
        v-if="false"
        icon
        x-small

        @click="$emit('update:is-user-filter-sidebar-active',false)"
      >
        <v-icon> {{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-sheet>

    <div>
      <v-text-field
        v-model="filter.uid"
        label="ID"
        class="rounded-0"
        filled
        hide-details
      />
      <v-text-field
        v-model="filter.adsoyad"
        label="Ad Soyad"
        class="rounded-0"
        filled
        hide-details
      />
      <v-text-field
        v-model="filter.username"
        label="Kullanıcı Adı"
        class="rounded-0"
        filled
        hide-details
      />

      <v-select
        v-model="filter.cust_group"
        label="Müşteri Grubu"
        :items="customerGroupOptions"
        class="rounded-0"
        :menu-props="{ bottom: true, offsetY: true }"
        filled
        clearable
        hide-details
      ></v-select>
      <v-text-field
        v-model="filter.cari_kod"
        label="Cari Kodu"
        class="rounded-0"
        filled
        hide-details
      />
      <v-select
        v-model="filter.pricelist"
        label="Fiyat Listesi"
        :items="pricelistOptions"
        class="rounded-0"
        :menu-props="{ bottom: true, offsetY: true }"
        filled
        clearable
        hide-details
      ></v-select>
      <v-text-field
        v-model="filter.email"
        label="E-Posta"
        class="rounded-0"
        filled
        hide-details
      />
      <v-select
        v-model="filter.aktif"
        label="Durum"
        :items="statusOptions"
        item-text="title"
        item-value="value"
        class="rounded-0"
        :menu-props="{ bottom: true, offsetY: true }"
        filled
        clearable
        hide-details
      ></v-select>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="filter.updated"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filter.updated"
            label="Son Giriş"
            class="rounded-0"
            filled
            hide-details
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          range
          no-title
          :locale-first-day-of-year="1"
          :first-day-of-week="1"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            class="text-capitalize"
            @click="modal = false"
          >
            Vazgeç
          </v-btn>
          <v-btn
            depressed
            small
            class="text-capitalize"
            @click="$refs.dialog.save(date)"
          >
            Tamam
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <template v-slot:append>
      <div class="pa-3">
        <v-btn
          block
          text
          small
          rounded
          plain
          class="mb-3"
          @click="filterClear"
        >
          Temizle
        </v-btn>

        <v-btn
          x-large
          rounded
          block
          class="text-capitalize"
          color="secondary"
          @click="filtering"
        >
          Tamam
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { mdiClose, mdiFilter, mdiFilterOff } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Vue from 'vue'

export default {
  model: {
    prop: 'isFilterUserSidebarActive',
    event: 'update:is-user-filter-sidebar-active',
  },
  props: {
    isFilterUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    customerGroupOptions: {
      type: Array,
      required: true,
    },
    pricelistOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isDark } = useAppConfig()
    const filter = ref({
      uid: '',
      aktif: '',
      cust_group: '',
      cari_kod: '',
      pricelist: '',
      adsoyad: '',
      username: '',
      email: '',
      updated: '',
    })
    const search = ref()
    const date = ref(null)
    const modal = ref(false)
    const isLoading = ref(false)
    const items = ref([])
    const isFilter = ref(0)

    const filtering = () => {
      emit('filter-data', filter.value)
      emit('update:is-user-filter-sidebar-active', false)
    }
    const filterClear = () => {
      emit('filter-data', {})
      emit('update:is-user-filter-sidebar-active', false)
      filter.value = {
        uid: '',
        aktif: '',
        cust_group: '',
        cari_kod: '',
        pricelist: '',
        adsoyad: '',
        username: '',
        email: '',
        updated: '',
      }
      Vue.swal({
        title: 'Temizlendi',
        text: 'Filitre Temizlendi',
        icon: 'success',
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      })
    }

    watch(
      filter,
      item => {
        let i = 0
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(item)) {
          if (value) {
            i += 1
          }
        }
        isFilter.value = i
      },
      { deep: true },
    )

    return {
      date,
      modal,
      items,
      isFilter,
      isLoading,
      isDark,
      filter,
      filtering,
      filterClear,
      search,

      icons: {
        mdiFilter,
        mdiFilterOff,
        mdiClose,
      },
    }
  },
}
</script>
